import React, { useState, useEffect } from "react";
import FileUpload from "react-material-file-upload";
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import axios from "axios";

export default function FileUploader({ initiateUpload, item }) {
  const [percentage, setPercentage] = useState(0);
  const [ipfsUrl, setIpfsUrl] = useState("");
  const [start, setStart] = useState(initiateUpload);

  const AddToIPFS = async (item) => {
    try {
      // console.log(process.env.REACT_APP_INFURA_AUTH)
      const formData = new FormData();
      formData.append("file", item);
      const res = await axios.post(
        "https://ipfs.infura.io:5001/api/v0/add",
        formData, {
        headers: {
          "Authorization": `Basic ${process.env.REACT_APP_INFURA_AUTH}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setPercentage(percentCompleted)
        }
      }
      );
      if (res === null) {
        throw Error("Failed to add data to IPFS");
      }
      setIpfsUrl(`https://niftron.infura-ipfs.io/ipfs/${res.data.Hash}`)
    } catch (e) {
      console.log(e)
    }

  }

  useEffect(() => {
    const add = async () => {
      await AddToIPFS(item)

    }
    add()
  }, []);

  return (

    <Container maxWidth="lg">
      {item && <Box >
        <Grid item xs={12} md={6}>
          <List >
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="copy">
                  <CopyAllIcon onClick={()=>{
                       navigator.clipboard.writeText(ipfsUrl).then(() => {
                        // Alert the user that the action took place.
                        // Nobody likes hidden stuff being done under the hood!
                        alert("Copied to clipboard");
                    });
                  }}/>
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar
                  variant="square"
                  style={{ margin: "20px" }}
                  alt={item.name}
                  src={ipfsUrl}
                  sx={{ width: 50, height: 50 }}
                />

              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                secondary={ipfsUrl}

              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>

                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">{`
                    ${percentage}%`}</Typography>
                </Box>
              </Box>

            </ListItem>
            <Box sx={{ width: '85%', mr: 1, float: "right" }}>
              <LinearProgress variant="determinate" value={percentage} />
            </Box>
          </List>
        </Grid>
      </Box>
      }


    </Container>
  );


}