import { useState } from "react";
import FileUpload from "react-material-file-upload";
import Container from '@mui/material/Container';
import { Button } from "@mui/material";
import FileUploader from "./components/FileUploader";

export default function App() {
  const [files, setFiles] = useState([]);
  const [initiateUpload, setInitiateUpload] = useState(false);

  const handleFilesUpload = () => {
    setInitiateUpload(true);
  };

  return (
    <div className="App">
      <Container maxWidth="md">
        <h1>Niftron IPFS Beta</h1>

        <FileUpload
          buttonText="Select Files"
          value={files}
          onChange={(data) => {
            setInitiateUpload(false);
            setFiles(data)
          }}
          multiFile={true}
          leftLabel="or"
          rightLabel="to select files"
          buttonLabel="click here"
          buttonRemoveLabel="Remove all"
          maxFileSize={10}
          maxUploadFiles={0}
          bannerProps={{ elevation: 0, variant: "outlined" }}
          containerProps={{ elevation: 0, variant: "outlined" }}
        />  
        <Button variant="contained" styles={{ float: "right" }} disabled={files.length == 0}
          onClick={handleFilesUpload}>Upload Files</Button>
        {
          files && files.length > 0 && files.map((file, index) => {


            if (initiateUpload) { return <FileUploader key={index} item={file} index={index} initiateUpload={initiateUpload} /> }
          }
          )
        }



      </Container>
    </div >
  );
}